import React from 'react';
import styles from './CartTaxAndFees.scss';
import Text from '../../core-components/Text';
import dataHooks from '../../data-hooks';
import { useTranslation } from '@wix/yoshi-flow-editor';
import InfoIcon from 'wix-ui-icons-common/on-stage/Info';
import { PopoverTriggerAction } from 'wix-ui-tpa';
import { ServiceFee } from '@wix/restaurants-client-logic';
import Popover from '../Popover';

export interface TaxAndFeesPopoverProps {
  tax?: string;
  serviceFees?: ServiceFee[];
  taxesAndServiceFeesTotalAmount?: string;
}

const TaxAndFeesPopover: React.FC<TaxAndFeesPopoverProps> = ({ tax, serviceFees, taxesAndServiceFeesTotalAmount }) => {
  const { t } = useTranslation();

  const getPopoverContentRow = ({ title, amount }: { title: string; amount: string }) => {
    return (
      <div className={styles.row}>
        <Text typography="p2-m" className={styles.title}>
          {title}
        </Text>
        <Text typography="p2-m" className={styles.price}>
          {amount}
        </Text>
      </div>
    );
  };

  return serviceFees ? (
    <div className={styles.row} >
      <Text typography="p2-m"  className={styles.title} data-hook={dataHooks.cartSubtotalTaxTitle}>
        {tax
          ? t('checkout_main_myorder_servicetax_label', 'Service fees & tax')
          : t('checkout_main_myorder_service_label', 'Service Fees')}
        <Popover
          className={styles.taxesPopoverWrapper}
          fixed
          showArrow
          placement="top"
          zIndex={1020}
          triggerAction={PopoverTriggerAction.hover}
          data-hook={dataHooks.cartSubtotalTaxPopover}
          popoverElement={<InfoIcon  tabIndex={0} width={20} height={20} />}
          popoverContent={
            <React.Fragment>
              {tax && getPopoverContentRow({ title: t('cart_summary_tax', 'Tax'), amount: tax })}
              {serviceFees.map((fee) =>
                getPopoverContentRow({
                  title: fee.name || t('cart_summary_service_fee', 'Service Fee'),
                  amount: fee.amount,
                }),
              )}
            </React.Fragment>
          }
          width={190}
          wiredToSiteColors
        />
      </Text>
      <Text typography="p2-m" className={styles.price} data-hook={dataHooks.cartSubtotalTaxTotalAmount}>
        {taxesAndServiceFeesTotalAmount}
      </Text>
    </div>
  ) : null;
};

TaxAndFeesPopover.displayName = 'TaxAndFeesPopover';

export default TaxAndFeesPopover;
